<template>
  <div v-html="markup" :class="cssClass || 'mb-4'" />
</template>

<script>
export default {
  props: [
    'content',
    'cssClass'
  ],
  computed: {
    markup: function() {
      let markup = ''
      if (this.content.length) {
        this.content.map((c, i) => {
          switch(c.nodeType) {
            case 'embedded-asset-block':
              if(c.data.target.fields.file) {
                if (c.data.target.fields.file.url.includes('.mp4')) {
                  markup += `
                  <video controls>
                    <source src="${c.data.target.fields.file.url}" type="video/mp4">
                  </video>
                  `
                } else {
                  markup += `<img style="margin:auto;" src="${c.data.target.fields.file.url}" alt="${c.data.target.fields.description}" />`
                }
              }
              break
            case 'heading-1':
              markup += `<h1 class="text-6xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h1>`
              break
            case 'heading-2':
              markup += `<h2 class="text-5xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h2>`
              break
            case 'heading-3':
              markup += `<h3 class="text-4xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h3>`
              break
            case 'heading-4':
              markup += `<h4 class="text-3xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h4>`
              break
            case 'heading-5':
              markup += `<h5 class="text-2xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h5>`
              break
            case 'heading-6':
              markup += `<h6 class="text-xl uppercase text-center mb-4 font-bold">${this.getText(c.content)}</h6>`
              break
            case 'unordered-list':
              markup += `<ul class="mb-2 list-disc pl-4">${this.getText(c.content)}</ul>`
              break
            case 'ordered-list':
              markup += `<ol class="mb-2 list-decimal pl-4">${this.getText(c.content)}</ol>`
              break
            default:
              markup += `<p class="mb-2">${this.getText(c.content)}</p>`
          }
        })
      }
      return markup
    }
  },
  methods: {
    getText: function(input) {
      let output = ''
      input.map((t, i) => {
        switch(t.nodeType) {
          case 'paragraph':
            output += `<p>${this.getText(t.content)}</p>`
            break
          case 'list-item':
            output += `<li>${this.getText(t.content)}</li>`
            break
          case 'hyperlink':
            output += `<a href="${t.data.uri}">${this.getText(t.content)}</a>`
            break
          case 'unordered-list':
            output += `<ul class="mb-2 inner-list pl-4">${this.getText(t.content)}</ul>`
          break  
          default:
            output += this.getMarks(t)
        }
      })
      return output
    },
    getMarks: function(input) {
      let output = ''
      if(input.marks && input.marks.length > 0) {
        input.marks.map((m, i) => {
          switch(m.type) {
            case 'bold':
              output += '<strong>'
              break
            case 'italic':
              output += '<em>'
              break
            case 'underline':
              output += '<span class="underline">'
              break
          }
        })
        output += input.value
        let marks = [].concat(input.marks)
        marks.reverse().map((m, i) => {
          switch(m.type) {
            case 'bold':
              output += '</strong>'
              break
            case 'italic':
              output += '</em>'
              break
            case 'underline':
              output += '</span>'
              break
          }
        })
      } else {
        output = input.value
      }
      return output
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.two-column {
  .details-section-details {
    >div {
      @media (min-width: $md) {
        max-width: 50%;
      }
    }
  }
}

.party-city-packages {
  div {
    .details-section-details {

      div:not(.birthdays-detail) {
        width: 100%;
        @media (min-width: $md) {
          width: 47.5%
        }

        .package {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin: 20px 0;

          @media (min-width:$md) {
            height: 280px;
            flex-direction: row-reverse;
            align-items: flex-start;
            justify-content: space-between;
          }

          p {
            margin: 0!important;
          }

          img {
            width: 100%;
            margin: 0!important;
            @media (min-width: $md) {
              width: 47.5%
            }
          }

          div {
            width: 100%;
            @media (min-width: $md) {
              width: 47.5%
            }

            h4 {
              font-size: 20px;
              text-align: center;
              margin-bottom: 5px;
              
              @media (min-width: $md) {
                text-align: right;
              }
            }

            p {
              text-align: center;

              @media (min-width: $md) {
                text-align: right;
              }
            }
          }
        }

        &:nth-of-type(2) {
          .package {
            @media (min-width:$md) {
              flex-direction: row;
            }
            div {
              h4 {
                @media (min-width: $md) {
                  text-align: left;
                }
              }

              p {
                @media (min-width: $md) {
                  text-align: left;
                }
              }
            }
          }
        }

        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../assets/scss/_variables';

.birthdays-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>